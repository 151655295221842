import { Container, Headline, Inquiries, Legal } from './ContactForm.styles'

import MarketoForm from "../MarketoForm"
import PropTypes from 'prop-types';
import React from 'react';

export default function ContactForm(props) {

  return (
    <Container>
      <Headline id="form__headline">{props.headline}</Headline>
      <MarketoForm labelBellow={false} id={props.id} onSubmit={props.onSubmit} eventCategory="contact us interaction" eventAction="submit contact us form_cta"></MarketoForm>
      
      <Inquiries id="form__inquiries">Non-corporate inquiries?  <a href="https://driver.grubhub.com">For drivers.</a>  <a href="https://get.grubhub.com">For restaurants.</a></Inquiries>
      <Legal id="form__legal">By clicking “Submit” or by using this site, you agree to Grubhub's <a href="https://www.grubhub.com/legal/terms-of-use">terms of use</a> and <a href="https://www.grubhub.com/legal/privacy-policy">privacy policy</a>.</Legal>

    </Container>
  );
}

ContactForm.propTypes = {
  headline: PropTypes.string,
  id: PropTypes.number.isRequired,
  eventCategory: PropTypes.string,
  eventAction: PropTypes.string,
};

ContactForm.defaultProps = {
  headline: null,
  id: null,
  eventCategory: "marketo form interaction",
  eventAction: "submit_cta"
};