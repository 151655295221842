import Button from "../components/global/Button"
import ContactForm from "../components/global/ContactForm"
import { GrMail } from "react-icons/gr"
import Layout from "../components/global/Layout"
import React from "react"
import { RiPhoneFill } from "react-icons/ri"
import SectionWrapper from "../components/sections/SectionWrapper"
import { queries } from "../breakpoints"
import styled from 'styled-components';

const Contact = ({ location }) => {

  const showThankYouScreen = () => {
    // Show the thank you message  
    let thankYou = document.getElementById('form-thank-you')
    thankYou.style.display = 'block';

    // Hide the form
    let form = document.getElementById('contact-form')
    form.style.display = "none"
  }

  return (
    <Layout 
      location={location} 
      title={"Contact Us | Get in Touch | Grubhub Corporate Accounts"} 
      description={"Want to get in touch with Grubhub for information about setting up a Corporate Account? Contact us today, it's easy to get started."}
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "contact us",
        pageName: "contact us",
        brand: "grubhub"
      }}
      >
        <StyledSectionWrapper id="contact-form">
          <div className="row">
              <StyledHeader className="col-lg-6 col-12 text-center text-lg-left">
                  <Headline>Questions about Grubhub Corporate?</Headline>
                  <Subheadline>Fill out the form to learn more about corporate accounts.</Subheadline>
                  <Image src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627673474/Grubhub%20Corporate%20Website/2021/Photography%20Elements/5-ContactUs/GeneralContactUs-Image.jpg" alt="Contact us" />
              </StyledHeader>
              <StyledFormContainer className="col-lg-6 col-12">
                  <ContactForm 
                    id={1671} 
                    headline="Get in touch" 
                    eventCategory="contact us interaction"
                    eventAction="submit contact us form_cta"
                    onSubmit={() => showThankYouScreen()}/>
              </StyledFormContainer>
          </div>
        </StyledSectionWrapper>

        {/* Thank you page (hidden until successful submit) */}
        <SectionWrapper id="form-thank-you" style={{ display: 'none' }}>
          <ThankYouContainer className="row">
            <div className="col-lg-6 col-12" style={{padding:0}}>
              <ThankYouImage />
            </div>
            <ThankYouMessageContainer className="col-lg-6 col-12">
              <img 
                width={125}
                alt=""
                src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627673958/Grubhub%20Corporate%20Website/2021/Navigation/SuccessState-Checkmark.svg"/>
              <h2>Thanks for reaching out, we'll be in touch shortly!</h2>
              <p>In the meantime, check out our blog to learn more about supporting your hybrid workforce.</p>
              <Button to="/blog/" typee="primary">See blog</Button>
            </ThankYouMessageContainer>
          </ThankYouContainer>
        </SectionWrapper>

        <SectionWrapper backgroundColor="#F6F6F6">
          <PageFooter>
            <FooterCol>
              <FooterHeadline>Need help with an order or your account?</FooterHeadline>
            </FooterCol>
            <FooterCol>
              <ContactInfo>
                <h6>ORDER INQUIRIES</h6>
                <InquiriesPhone><RiPhoneFill/><a href="tel:844-478-2249">(844) 478-2249</a></InquiriesPhone>
                <InquiriesEmail>
                  <GrMail/><a href="mailto:corporatecare@grubhub.com">corporatecare@grubhub.com</a>
                </InquiriesEmail>
              </ContactInfo>
              <ContactInfo>
                <h6>ACCOUNT INQUIRIES</h6>
                <InquiriesPhone><RiPhoneFill/><a href="tel:844-478-2249">(844) 478-2249</a></InquiriesPhone>
                <InquiriesEmail>
                  <GrMail/><a href="mailto:clients@grubhub.com">clients@grubhub.com</a>
                </InquiriesEmail>
              </ContactInfo>
            </FooterCol>
          </PageFooter>
        </SectionWrapper>
    </Layout>
  )
}

export default Contact

const StyledSectionWrapper = styled(SectionWrapper)`
  @media(${queries.max_break_md}){
    padding:0;
    padding-bottom: 50px;
    .container{
      max-width: unset;
    }
  }
`;

const StyledHeader = styled.div`
  @media(${queries.max_break_md}){
    display: grid;
    grid-template-areas: "image" "headline" "subheadline";
    align-content: center;
    padding: 0;
  }
`;

const Headline = styled.h1`
  font-weight: bold;
  font-size: clamp(var(--fontSize-5), -0.875rem + 8.333vw, var(--fontSize-7));
  @media(${queries.max_break_md}){
    grid-area: headline;
    padding: 0 15px;
  }
  
`;

const Subheadline = styled.p`
  font-size: var(--fontSize-2);
  @media(${queries.max_break_md}){
    grid-area: subheadline;
    padding: 0 15px;
  }
`;

const Image = styled.img`
  width: 90%;
  border-radius: 4px;
  margin-bottom: 50px;
  @media(${queries.max_break_md}){
    grid-area: image;
    width:100%;
    border-radius: 0;
    clip-path: circle(70% at 50% 0%);
    margin-bottom: 20px;
  }

`;

const StyledFormContainer = styled.div`
  @media(${queries.max_break_md}){
    padding:20px 40px 0; 
  }
  @media(${queries.max_break_sm}){
    padding:20px 20px 0; 
  }
`;

const PageFooter = styled.div`
  display: flex;
  flex-flow:column;

  @media(${queries.min_break_lg}){
    flex-flow: row;
  }

`;

const FooterHeadline = styled.h4`
  font-size: var(--fontSize-3);
  font-weight: bold;
  @media(${queries.max_break_md}){
    text-align: left;
  }
`;

const FooterCol = styled.div`
  display: flex;
  align-items: center;
  flex:1;
  justify-content: center;
  text-align: center;
  &:first-child{
    margin-bottom: 25px;
  }
  @media(${queries.min_break_lg}){
    justify-content: space-around;
    &:first-child{
      margin-bottom: 0;
    }
  }
  @media(${queries.max_break_md}){
    flex-flow:column;
    align-items: flex-start;
  }

`;

const ContactInfo = styled.div`
  padding: 0 10px;
  flex:1;
  @media(${queries.max_break_md}){
    &:first-child{
      margin-bottom: 20px;
    }
    text-align:left;
  }
`;

const InquiriesPhone = styled.p`
  white-space: nowrap;
  margin: 0;

  svg {
      margin-right: 5px;
  }
`;

const InquiriesEmail = styled.p`
  white-space: nowrap;

  svg {
      margin-right: 5px;
  }
`;

const ThankYouContainer = styled.div`
  background-color: #f6f6f6;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
  -moz-box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
  font-family: var(--font-body);
  box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
  min-height: 50vh;
  border-radius:4px;
`;
const ThankYouImage = styled.div`
  background-image: url('https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,fl_lossy,q_80/v1627674140/Grubhub%20Corporate%20Website/2021/Photography%20Elements/5-ContactUs/GeneralContactUs-Image-SuccessState.jpg');
  width: 100%;
  height: 100%;
  background-size:cover;
  background-position: center center;
  border-radius:4px 0 0 4px;
  @media(${queries.max_break_lg}){
    min-height:350px;
  }
`;
const ThankYouMessageContainer = styled.div`
  display:flex;
  flex-flow:column;
  justify-content:center;
  align-items: center;
  text-align:center;
  padding: 50px;
  h2{
    font-weight:bold;
    font-size: var(--fontSize-5);
    margin:20px 0;
    @media(${queries.max_break_md}){
      font-size: var(--fontSize-3);
    }
  }
  p{
    font-size: var(--fontSize-2);
    @media(${queries.max_break_md}){
      font-size: var(--fontSize-1);
    }
  }
`;
