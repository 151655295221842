import { queries } from "../../../breakpoints"
import styled from 'styled-components';

export const Container = styled.div`
    background-color: #f6f6f6;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    -webkit-box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
    -moz-box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
    font-family: var(--font-body);
    box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
    @media(${queries.min_break_md}){
        padding: 55px;
    }

    .mktoFormCol {
        margin-bottom: 25px;
        @media(${queries.min_break_md}){
            margin-bottom: 35px;
            width: 50% !important;

            &:first-child {
                margin-right: 10px;
            }

            &:nth-child(2) {
                margin-left: 10px;
            }
        }
    }

    .mktoLabel {
        display: flex;
        font-size: var(--fontSize-0);
        font-weight: 300;
        margin-bottom: 3px;
        color: var(--color-secondary);
    }

    .mktoFormRow {
        display: flex;
        width: 100% !important;
        flex-flow: column;
        @media(${queries.min_break_md}){
            flex-flow: row;
        }
    }

    input {
        height: 40px;
        border: 1px solid #AEAEAE;
        border-radius: 2px;
        width: 100% !important;
        font-family: var(--fontFamily-body);
        padding: 0 10px;
    }

    .mktoButton {
        display: inline;
        padding: 12px 30px;
        border-radius: 100px;
        font-weight: bold;
        font-size: var(--fontSize-1);
        min-width: 175px;
        display: inline-block;
        text-align: center;
        background-color: var(--color-primary);
        color: white;
        font-family: var(--font-body);
        border: none;
        transition: all .1s;

        &:hover {
            color: white;
            text-decoration: none;
            background-color: var(--color-primary-dark);
        }
        &:active{
            background-color: var(--color-primary-darker);
        }
    }

    // Validation styles
    .mktoInvalid{
        border-color:#C3363E;
    }
    .mktoErrorMsg{
        font-family: var(--fontFamily-body);
        color:#C3363E;
        font-size: 12px;
    }

    .mktoValid{
        border-color: #13AA37;
    }
    @media(${queries.max_break_md}){
        #Phone, #PostalCode{
            max-width:200px;
        }
    }
    .mktoRadioList {
        max-width: 200px;
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .mktoRadioList input[type="radio"] {
        top: inherit !important;
        left: inherit !important;
        width: inherit !important; 
        position: inherit !important;
        float: right;
        clear: right;
        width: 20px !important;
        height: 20px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }
    .mktoRadioList label {
        display: block !important;
        float: left;
        clear: left;
        margin: 0 !important;
        padding: 0 !important;
        margin-right: 20px!important;
        padding-left: 4px!important;
        font-size: var(--fontSize-0);
        font-weight: 300;
    }
`;

export const Headline = styled.h4`
    align-self: flex-start;
    font-weight: bold;
    font-size: var(--fontSize-4);
    margin-bottom: 20px;
`;

export const Form = styled.form`
    width:100%;
`;

export const Legal = styled.p`
    font-size: var(--fontSize-legal);
    width: 100%;
    align-self: flex-start;
    margin-top: 15px;
    @media(${queries.min_break_md}){
        width: 70%;
    }

`;

export const Inquiries = styled.p`
    align-self: flex-start;
    margin-top: 30px;
    a{
        margin-right: 10px;
        font-family: var(--font-body);
        font-weight: 300;
        white-space: nowrap;
        @media(${queries.min_break_sm}){
            &:first-child{
                margin-left: 5px;
            }
        }
    }
`;
